import formatMessage from 'format-message';
import { SORT_AUTHOR, SORT_DOWNLOADS, SORT_FAVORITE, SORT_RECENT, SORT_TITLE } from '../../../constants/search';

export const headers = [
  {
    id: SORT_TITLE,
    label: formatMessage('Title'),
    width: '40%',
    sortable: true,
  },
  {
    id: 'grade',
    label: formatMessage('Grade level'),
    sortable: false,
  },
  {
    id: SORT_AUTHOR,
    label: formatMessage('Author'),
    sortable: true,
  },
  {
    id: SORT_RECENT,
    label: formatMessage('Last updated'),
    sortable: true,
    getTextAlign: (isDesktopView) => isDesktopView ? 'center' : 'start',
  },
  {
    id: SORT_FAVORITE,
    label: formatMessage('Favorites'),
    sortable: true,
    getTextAlign: (isDesktopView) => isDesktopView ? 'center' : 'start',
  },
  {
    id: SORT_DOWNLOADS,
    label: formatMessage('Downloads'),
    sortable: true,
    getTextAlign: (isDesktopView) => isDesktopView ? 'center' : 'start',
  },
];
