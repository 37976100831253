import React, { useMemo } from 'react';
import ResultsError from './error';
import ResultPlaceholder from '../result/placeholder';

const numberOfPlaceholders = 16;

const PlaceholderCards = (props) => {
  const placeholderData = Array.from(
    { length: props.count },
    (_, index) => ({ id: index })
  );
  return <Cards data={placeholderData} onRender={() => <ResultPlaceholder />} />;
};

const Cards = (props) => {
  return props.data.map((learningObject) => (
    <li key={learningObject.id}>
      {props.onRender(learningObject)}
    </li>
  ));
};

const ResultCards = (props) => {
  const placeholders = useMemo(() => <PlaceholderCards count={numberOfPlaceholders} />, []);
  const results = props.results.store.get();
  const isInitialLoading = props.results.store.showLoading();
  const onRetry = () => props.onRetry(props.results.nextCursor);

  return (
    <React.Fragment>
      <ol className="card-layout" ref={props.loadMoreFocusContainerRef}>
        {
          isInitialLoading
            ? placeholders
            : <Cards data={results} onRender={props.onCardRender} />
        }
      </ol>
      {props.results.error && <ResultsError onRetryClick={onRetry} />}
    </React.Fragment>
  );
};

export default ResultCards;
