import React from 'react';
import { Pagination } from '@instructure/ui-pagination';
import PropTypes from 'prop-types';

const shouldShowPagination = ({ totalPages, currentPage }) => {
  return totalPages > 0 && currentPage > 0 && currentPage <= totalPages;
};

const ResultPagination = ({ totalPages, currentPage, onPageChange, ...rest }) => {
  if (!shouldShowPagination({ totalPages, currentPage })) {
    return null;
  }

  const onResultPaginationPageChange = (currentPage, previousPage) => {
    if (currentPage === previousPage) {
      return;
    }
    const isPreviousPageNavigation = currentPage === previousPage - 1;
    onPageChange(currentPage, { isPreviousPageNavigation });
  };

  return (
    <Pagination
      as="nav"
      margin="mediumSmall 0"
      variant="input"
      currentPage={currentPage}
      totalPageNumber={totalPages}
      data-testid="ResultPagination"
      onPageChange={onResultPaginationPageChange}
      {...rest}
    />
  );
};

ResultPagination.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  margin: PropTypes.string,
};

export default ResultPagination;
