import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@instructure/ui-alerts';
import formatMessage from 'format-message';

import { UsersActions } from '../../../actions';
import AnalyticsActions from '../../../actions/analytics';
import SearchInfo from '../../search/info';
import ResultsResponsive from './results-responsive';
import Download from './download';

const ERROR_MESSAGES = {
  canvas_user_404: formatMessage(
    'This user account no longer exists in Canvas.'
  ),
  default: formatMessage('There was an error.')
};

export default class TableObjects extends React.Component {
  static propTypes = {
    baseParams: PropTypes.object,
    fetchResults: PropTypes.func.isRequired,
    results: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    statsMessage: PropTypes.node,
    searchPrivateObjects: PropTypes.bool,
    query: PropTypes.string,
    queryPlaceholder: PropTypes.string,
    queryArialLabel: PropTypes.string
  };

  constructor (props) {
    super(props);
    this.state = {
      isNewSearch: true
    };

    this.props.fetchResults({
      showPublic: true,
      ...props.baseParams,
      ...props.router.query
    });
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (this.state.isNewSearch && newProps.results.searchSuccessful) {
      this.setState({ isNewSearch: false });
      AnalyticsActions.viewedAccountStatsResults(newProps.results.count);
    }

    if (this.props.router !== newProps.router) {
      const newParams = Object.assign(
        { showPublic: true },
        newProps.baseParams,
        newProps.router.query
      );
      this.props.fetchResults(newParams);
      this.setState({ isNewSearch: true });
    }
  }

  render () {
    const params = Object.assign(
      {},
      this.props.baseParams,
      this.props.router.query
    );

    const fetchResults = (cursor) => this.props.fetchResults(params, cursor);
    const hasResults = Boolean(this.props.results.count);
    const showEmptyMessage =
      !this.state.isNewSearch && !this.props.results.loading && !hasResults;
    return (
      <div>
        {this.props.users.loadUserError && (
          <span className="Alert--floating">
            <Alert
              variant="error"
              renderCloseButtonLabel={formatMessage('Close')}
              margin="small"
              timeout={10000}
              onDismiss={() => UsersActions.clearLoadUserError()}
            >
              {ERROR_MESSAGES[this.props.users.loadUserError.message] ||
                ERROR_MESSAGES.default}
            </Alert>
          </span>
        )}
        <SearchInfo {...this.props} />
        {showEmptyMessage ? (
          this.props.emptyMessage
        ) : (
          <React.Fragment>
            <Download {...this.props} />
            <ResultsResponsive {...this.props} fetchResults={fetchResults} />
          </React.Fragment>
        )}
      </div>
    );
  }
}
