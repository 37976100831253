import React from 'react';
import formatMessage from 'format-message';
import { Select } from '../../common/select';
import { hasSortOrder, joinSortingValues, splitSortingValues } from './util';

export default function SearchSortingSelect (props) {
  const onSortByChange = (event, option) => {
    const { sortBy, sortOrder } = splitSortingValues(option.value);
    props.onSortByChange({ sortBy, sortOrder });
  };

  const value = hasSortOrder(props.sortBy)
    ? joinSortingValues(props.sortBy, props.sortOrder)
    : props.sortBy;

  return (
    <Select
      name="sortBy"
      className="SearchOptions-sortSelect"
      aria-label={formatMessage('Sort results by')}
      assistiveText={formatMessage('Sort results by')}
      onChange={onSortByChange}
      value={value}
      options={props.sortingOptions}
    />
  );
}
