import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import AppHandler from '../components/app-handler';
import Search from '../components/search';
import NotificationCenter from '../components/common/notification-center/';
import SearchOptions from '../components/search/search-options';
import {
  SearchActions,
  SessionActions,
  RouterActions
} from '../actions/';
import { getState, subscribe } from '../store';
import { withDefaultSearchFilters } from '../components/search/with-default-search-filters';
import { withListLoaders } from '../components/common/with-list-loaders';
import _ from 'lodash';
import PageTitle from '../components/common/page-title';
import MotdAlert from '../components/search/motd-alert';
import { getDefaultSearchSortingOptions } from '../components/search/sorting/default-search-sorting-options';
import { getSortingFromQuery } from './util/get-sorting-from-query';

/* eslint-disable react/prop-types */
const View = ({ queryParams }) => {
  const {
    q,
    types,
    scopeIds,
    gradeIds,
    subjectIds,
    showPublic,
    contentCategoryTypes
  } = queryParams;
  const { sortBy, sortOrder } = getSortingFromQuery(queryParams);
  const state = getState();

  return (
    <AppHandler
      activeHeader="search"
      consortiums={state.consortiums}
      groups={state.groups}
      isManager={state.groups.isManager}
      logout={SessionActions.logout}
      router={state.router}
      session={state.session}
      showSidebar
      updates={state.updates}
    >
      <div className="responsive-results-width">
        <Head title="Search" />
        <PageTitle title={formatMessage('Search')} />
        <div className="HeaderLinks-hideBorder" />
        <NotificationCenter notificationCenter={getState().notificationCenter} />
        <MotdAlert onlyForAdmins />
        <main className="Main">
          <SearchOptions
            featureFlags={state.featureFlags}
            searchPrivateObjects
            onChange={SearchActions.update}
            query={q}
            types={types ? types.split(',') : []}
            contentCategoryTypes={contentCategoryTypes ? contentCategoryTypes.split(',') : []}
            scopeIds={scopeIds ? scopeIds.split(',') : []}
            gradeIds={gradeIds ? gradeIds.split(',') : []}
            subjectIds={subjectIds}
            sortBy={sortBy}
            sortOrder={sortOrder}
            sortingOptions={getDefaultSearchSortingOptions()}
            showPublic={showPublic}
            groups={state.groups.list}
            consortiums={state.consortiums}
            canShowApprovedContent={state.account.settings.allowApprovedContent}
            canShowPublic={
              state.account.settings.canShowPublic && state.session.sid && !state.session.publicOnly
            }
            canShowFeatured={state.account.settings.canShowFeatured}
            queryArialLabel={formatMessage(
              'Search Commons for course resources by tag, name, institution, or outcome'
            )}
            account={state.session.account}
          />
          <Search {...getState()} />
        </main>
      </div>
    </AppHandler>
  );
};

export const SearchPage = _.flowRight(withDefaultSearchFilters, withListLoaders)(View);

export default function ({ resolve, location, render, exiting }) {
  RouterActions.backTo(formatMessage('Search'), window.location.href);

  render(<SearchPage queryParams={location.query} />);
  resolve();

  const unsubscribe = subscribe(() => render(<SearchPage queryParams={location.query} />));
  exiting.then(unsubscribe);
}
