import React from 'react';
import Head from 'react-helmet';
import formatMessage from 'format-message';
import AppHandler from '../../components/app-handler';
import Resources from '../../components/account/resources';
import SearchOptions from '../../components/search/search-options';
import {
  SearchActions,
  SessionActions,
  RouterActions
} from '../../actions';
import AdminTabs from '../../components/common/admin-tabs';
import NotAuthenticated from '../../components/errors/401-not-authenticated';
import { getState, subscribe } from '../../store';
import { withListLoaders } from '../../components/common/with-list-loaders';
import AccountHeader from './account-header';
import Button from '../../components/common/button';
import { getDefaultSearchSortingOptions } from '../../components/search/sorting/default-search-sorting-options';
import { getSortingFromQuery } from '../util/get-sorting-from-query';

const handleJumpClick = function () {
  const h3 = document.querySelector('.Sidebar h3');
  if (h3) {
    h3.focus();
  }
};

/* eslint-disable react/prop-types */
const View = ({ queryParams }) => {
  const {
    q,
    types,
    scopeIds,
    gradeIds,
    subjectIds,
    contentCategoryTypes
  } = queryParams;
  const { sortBy, sortOrder } = getSortingFromQuery(queryParams);
  const state = getState();

  let groups = [];
  const {
    isAdmin = false,
    isAccountCurator = false,
    allowApprovedContent,
    publicOnly
  } = state.session;
  if (!state.groups.listIsLoading) {
    const { isManager = false } = state.groups;

    const isCuratorAndApproved = allowApprovedContent && isAccountCurator;
    const isAllowed = isAdmin || isManager || isCuratorAndApproved;

    if (!isAllowed) {
      return <NotAuthenticated />;
    }

    if (isManager) {
      groups = state.groups.list && state.groups.list.filter(({ isManager }) => isManager);
    } else {
      groups = state.groups.list;
    }
  }

  const canShowPublic = (isAdmin || isAccountCurator) && !publicOnly;
  const canShowAllManagedContent = !canShowPublic;
  const canShowAccountContent = !state.groups.isManager;
  const canShowConsortiumContent = isAdmin || isAccountCurator;

  return (
    <AppHandler
      activeHeader="account"
      consortiums={state.consortiums}
      groups={state.groups}
      session={state.session}
      router={state.router}
      showSidebar
      updates={state.updates}
      isManager={!!state.groups.isManager}
      logout={SessionActions.logout}
    >
      <div className="responsive-results-width manage-shared">
        <Head title={formatMessage('Managed Resources')} />
        <div className="AccountResources">
          <div className="App-main-inner-main-content-width">
            <AccountHeader />
            <AdminTabs
              activeTab="resources"
              session={state.session}
              isManager={state.groups.isManager}
            />
            <h2 className="AccountResources-title">{formatMessage('Managed Resources')}</h2>
            <div className="screenreader-only">
              <Button onClick={handleJumpClick}>
                {formatMessage('Jump to Consortiums and Groups filter')}
              </Button>
            </div>
          </div>
          <main className="Main AccountResources-main">
            <SearchOptions
              featureFlags={state.featureFlags}
              searchPrivateObjects
              onChange={SearchActions.update}
              query={q}
              types={types ? types.split(',') : []}
              scopeIds={scopeIds ? scopeIds.split(',') : []}
              gradeIds={gradeIds ? gradeIds.split(',') : []}
              contentCategoryTypes={contentCategoryTypes ? contentCategoryTypes.split(',') : []}
              subjectIds={subjectIds}
              sortBy={sortBy}
              sortOrder={sortOrder}
              sortingOptions={getDefaultSearchSortingOptions()}
              consortiums={state.consortiums}
              groups={groups}
              canShowApprovedContent={state.account.settings.allowApprovedContent}
              canShowPublic={canShowPublic}
              canShowAllManagedContent={canShowAllManagedContent}
              canShowAccountContent={canShowAccountContent}
              canShowConsortiumContent={canShowConsortiumContent}
              queryArialLabel={formatMessage('Search Managed Resources by tag, name, or outcome')}
              account={state.session.account}
            />
            <Resources {...state} />
          </main>
        </div>
      </div>
    </AppHandler>
  );
};

const ResourcesPage = withListLoaders(View);

export default async function ({ resolve, render, exiting, location }) {
  RouterActions.backTo(formatMessage('Managed Resources'), window.location.href);

  render(<ResourcesPage queryParams={location.query} />);
  resolve();

  const unsubscribe = subscribe(() => render(<ResourcesPage queryParams={location.query} />));
  await exiting.then(unsubscribe);
}
